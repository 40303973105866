<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('contact.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row class="spacing">
        <!-- 联系方式列表 -->
        <van-row v-for="item in banklist" :key="item.id">
          <van-swipe-cell>
            <van-row style="margin-bottom:10px">
              <van-cell
                v-for="info in JSON.parse(item.contact_info)"
                :key="info.field"
                :title="info.field"
                :value="info.value"
              />
            </van-row>
            <template #right>
              <van-button
                square
                :text="$t('contact.delbtn')"
                type="danger"
                class="delete-button"
                @click="del(item.id)"
              />
            </template>
          </van-swipe-cell>
        </van-row>
        <van-button class="submitBtn" @click="addshow">{{
          $t('contact.addbtn')
        }}</van-button>
        <!-- 添加联系方式 弹层 -->
        <van-popup v-model="isshow" class="reason">
          <h4>{{ $t('contact.select') }}</h4>
          <span
            @click="addbank(item.id)"
            v-for="item in typelist"
            :key="item.id"
            >{{ item.type_name }}</span
          >
        </van-popup>
        <van-popup class="setting" v-model="bankshow">
          <van-field
            v-for="item in contactfields"
            :key="item.field"
            :label="item.field"
            :placeholder="'请输入' + item.field"
            v-model="item.value"
          />
          <van-button
            style="border-right: 1px solid white;"
            @click="closebank"
            >{{ $t('common.cancel') }}</van-button
          >
          <van-button @click="submit">{{ $t('common.confirm') }}</van-button>
        </van-popup>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isshow: false,
      bankshow: false,
      banklist: [],
      typelist: [],
      contactfields: [],
      typeid: 1
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    // 获取联系方式列表
    async getlist() {
      const { data } = await this.$http.get('/home/home/getbanklist')
      if (data) {
        if (data.code === 200) {
          this.banklist = data.data.banklist
          this.typelist = data.data.typelist
          this.typelist.forEach(type => {
            type.fields = JSON.parse(type.fields)
          })
        } else {
          this.$toast.fail(this.$t('common.actionfail'))
        }
      }
    },
    // 删除
    async del(id) {
      const { data } = await this.$http.post('/home/home/delcontact', {
        id: id
      })
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.actionok'))
          this.getlist()
        } else {
          this.$toast.fail(this.$t('common.actionfail'))
        }
      }
    },
    // 显示弹层
    addshow() {
      this.isshow = true
    },
    // 添加操作
    addbank(id) {
      this.typeid = id
      this.typelist.forEach(type => {
        if (type.id === id) {
          this.contactfields = type.fields
        }
      })
      this.bankshow = true
    },
    // 关闭添加弹层
    closebank() {
      this.bankshow = false
    },
    // 添加操作
    async submit() {
      const { data } = await this.$http.post('/home/home/addcontact', {
        fields: this.contactfields,
        typeid: this.typeid
      })
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.actionok'))
          this.bankshow = false
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  height: 100vh;
  .spacing {
    margin-top: 1rem;
    .van-cell {
      height: 3.2rem;
      font-size: 0.93333rem;
      background-color: #fff !important;
    }
    .van-cell__title {
      text-align: left;
    }
    .van-cell:after {
      border-bottom: 0;
    }
    .delete-button {
      height: 100%;
    }
    .submitBtn {
      margin-top: 10%;
      width: 80%;
      letter-spacing: 0.53333rem;
      height: 4rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #004ea3;
      border-radius: 0.53333rem;
      border: none;
      box-shadow: 0 0 1.33333rem #004ea3;
    }
    .van-popup {
      width: 74%;
      border-radius: 0.53333rem;
      background-color: #fff !important;
    }
    .reason {
      h4 {
        height: 2.66667rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        height: 2.66667rem;
        align-items: center;
        display: block;
        font-size: 0.94667rem;
      }
    }
    .setting {
      width: 75%;
      border-radius: 0.4rem;
      background-color: #004ea3 !important;
      color: #fff !important;
      /deep/.van-field__label {
        width: 4.8rem;
        margin-right: 0.13333rem;
        font-size: 0.85333rem;
      }
      .van-cell {
        border-bottom: 1px solid #ddd;
      }
      .van-field input {
        border-bottom: 0.02667rem solid #d0d0d1;
      }
      button {
        width: 50%;
        padding-top: 4%;
        padding-bottom: 4%;
        border: 0;
        background-color: #004ea3 !important;
        border-top: 0.02667rem solid #fff;
        color: #fff;
        font-size: 1.06667rem;
      }
    }
  }
}
</style>
